import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
  DesktopOutlined,
  PieChartOutlined,
  AppstoreOutlined,
  EditOutlined
} from '@ant-design/icons';
import { Button, Layout, Menu, Space } from 'antd';
import Cookies from "js-cookie";
import AppTitle from '../../../assets/img_ci01.png';
import { flatten, setItem } from '../utils'
import UserDropdown from '../components/UserDropdown';
import { useAuth } from "../../../utils/hooks"
import { useFrame } from '../../../utils/store.js';

const { Header, Content, Sider } = Layout;


const staticMenuItems = [
  setItem('AI Chat (v2/Mir)', 'key-aichat-mir', process.env.REACT_APP_AICHAT_MIR, <DesktopOutlined />),
  setItem('안전 챗봇 (v2/Mir)', 'key-safety-mir', process.env.REACT_APP_SAFETY_MIR, <PieChartOutlined />),
  setItem('도면 검색 (beta)', 'key-drawing-lowcode', process.env.REACT_APP_DRAWING_LOWCODE, <AppstoreOutlined />),
  setItem('도면 검색 (Mir)', 'key-drawing-mir', process.env.REACT_APP_DRAWING_MIR, <AppstoreOutlined />),
  // setItem('AI Tools', 'key-aitools', '', <PieChartOutlined />, [
  //   // setItem('AskYourPdf', 'key-aitools-askyourpdf', process.env.REACT_APP_ASKYOURPDF),
  //   // setItem('이미지 생성', 'key-aitools-image-generator', process.env.REACT_APP_IMAGEGENERATOR),
  //   setItem('이메일/번역', 'key-aitools-mail-translation', process.env.REACT_APP_MAILTRANSLATION),
  //   setItem('회의록 요약', 'key-aitools-meeting-summary', process.env.REACT_APP_MEETINGSUMMARY),
  // ]),
  // setItem('AI Services', 'key-aiservices', '', <AppstoreOutlined />, [
  //   setItem('안젠봇', 'key-aiservices-safety', process.env.REACT_APP_SAFETY), 
  //   setItem('사내규정/매뉴얼', 'key-aiservices-manual', process.env.REACT_APP_MANUAL)])
  ];

const ChatView = () => {
  // const [tokenValid, setTokenValid] = useState()
  const [collapsed, setCollapsed] = useState(false);
  const [headerText, setHeaderText] = useState(staticMenuItems[0].label)
  const { iframeSrc, setIframeSrc } = useFrame()
  // const [iframeSrc, setIframeSrc] = useState(staticMenuItems[0].url)
  // const navigate = useNavigate()

  // useEffect(() => {
  //   const isTokenValid = async () => {
  //     const token = Cookies.get("refreshToken")
  //     const customHeaders = new Headers();
  //     customHeaders.append("Authorization", "Bearer " + token);
  
  //     const requestOptions = {
  //       method: "GET",
  //       headers: customHeaders,
  //       redirect: "follow"
  //     };
  
  //     const tokenValidFlag = await fetch(process.env.REACT_APP_AUTH_BASE_URL + '/user/me', requestOptions)
  //       .then((response) => {
  //         if (response.status == 200) {
  //             setTokenValid(true)
  //         } else {
  //             setTokenValid(false);
  //             navigate('/signin')
  //         }
  //       });

  //     return tokenValidFlag
  //   }
  //   isTokenValid()
  // }, [iframeSrc])
  
  useEffect(() => {
    setIframeSrc(staticMenuItems[0].url)
  }, [])

  return (
    <Layout>
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} breakpoint={'xl'} collapsedWidth={'72px'} >
        <div id="app_title_div">
          {collapsed ? <></> : 
            <img id='app_title_img' src={AppTitle}/>
          }
        </div>
        <Menu defaultSelectedKeys={[staticMenuItems[0].key]} mode='inline' items={staticMenuItems} onClick={({ key }) => {
          setHeaderText(flatten(staticMenuItems)[key].label) 
          setIframeSrc(flatten(staticMenuItems)[key].url)
          // if (key == 'key-aiservices-safety') {
          //   console.log('postMessageTest')
          //   window.postMessage((token, 'ds5rsq82u6o4p.cloudfront.net'), process.env.REACT_APP_SAFETY)
          // }
        }} />
      </Sider>
      <Layout>
        {/* <Header id='header'>
          <div id="header_div">
              {
                collapsed ? 
                headerText 
                :
                ""
              }
          </div>
          <UserDropdown />
          
        </Header> */}
        <Content id='content' width={collapsed ? "calc('100vh' - '80px')" : "calc('100vh' - '162px')"}>
          <iframe id='prompt_iframe' src={iframeSrc} frameBorder='0' marginWidth='0' width={'100%'} height={'100%'}
            sandbox='allow-same-origin allow-scripts allow-top-navigation allow-popups allow-pointer-lock allow-forms' />
        </Content>
      </Layout>
    </Layout>
  );
};
export default ChatView;